<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <section>
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- SECTION Form -->
        <b-form>
          <!-- SECTION Card agency basic info -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mt-1"
          >
            <!-- ANCHOR header -->
            <template #header>
              <div class="d-flex align-items-center justify-content-between w-100">
                <h6 class="m-0">
                  {{ $t('promotionAirline.edit.title') }}
                </h6>

                <b-button
                  v-if="readonly"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="py-75"
                  :disabled="!canAccess('customer.updateCustomer')"
                  @click="handleEdit"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('customer.edit') }}</span>
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="py-75"
                  :disabled="!canAccess('customer.updateCustomer')"
                  @click="handleCancelEdit"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('customer.cancel') }}</span>
                </b-button>
              </div>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1 mb-3">
                <!-- ANCHOR Code -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAirline.fields.code')"
                    rules="required|code|max:100"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAirline.fields.code') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.code"
                        maxlength="100"
                        minlength="1"
                        :disabled="readonly"
                        :formatter="upperCaseFormatter"
                        :placeholder="$t('promotionAirline.placeholder.code')"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Airline -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAirline.fields.airline')"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-0"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        <div>
                          {{ $t('promotionAirline.fields.airline') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <v-select
                        v-model="promotionDataToEdit.airline"
                        style="font-size: 1rem;"
                        :options="airlineFilterOptions"
                        :clearable="false"
                        :disabled="readonly"
                        :reduce="val => val.value"
                        :placeholder="$t('promotionAirline.placeholder.airline')"
                        @close="validationContext.validate"
                      >
                        <template #selected-option="data">
                          <div class="d-flex-center justify-content-between">
                            <span
                              class="d-block text-nowrap"
                            >
                              {{ data.label }}
                            </span>
                          </div>
                        </template>
                        <template v-slot:option="data">
                          <div class="d-flex-center justify-content-between">
                            <span
                              class=" d-block text-nowrap"
                            >
                              {{ data.label }}
                            </span>
                          </div>
                        </template>
                        <template #spinner="{ loading }">
                          <div
                            v-if="loading"
                            style="border-left-color: rgba(88, 151, 251, 0.71)"
                            class="vs__spinner"
                          />
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                    </b-form-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <!-- ANCHOR Start time -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAirline.fields.startTime')"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAirline.fields.startTime') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-datepicker
                        v-model="promotionDataToEdit.startTime"
                        type="date"
                        :locale="$i18n.locale"
                        :placeholder="$t('promotionAirline.placeholder.startTime')"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :min="new Date()"
                        :max="promotionDataToEdit.endTime ? new Date(promotionDataToEdit.endTime) : null"
                        :disabled="readonly"
                        show-decade-nav
                        hide-header
                        boundary="window"
                        flip
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR End date -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAirline.fields.endTime')"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAirline.fields.endTime') }}
                          <span class="text-danger">(*)</span>
                        </div>
                      </template>
                      <b-form-datepicker
                        v-model="promotionDataToEdit.endTime"
                        type="date"
                        :locale="$i18n.locale"
                        :placeholder="$t('promotionAirline.placeholder.endTime')"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :min="promotionDataToEdit.startTime ? new Date(promotionDataToEdit.startTime) : new Date()"
                        :disabled="readonly"
                        show-decade-nav
                        hide-header
                        boundary="window"
                        flip
                        :state="getValidationState(validationContext) === false ? false : null"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR priority -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAirline.fields.priority')"
                    rules="max:5"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAirline.fields.priority') }}
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.priority"
                        v-remove-non-numeric-chars.allNumber
                        number
                        maxlength="2"
                        lazy-formatter
                        :disabled="readonly"
                        :formatter="trimInput"
                        :placeholder="$t('promotionAirline.placeholder.priority')"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <!-- ANCHOR Flight start date -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.flightStartTime') }}
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToEdit.flightStartTime"
                      type="date"
                      :locale="$i18n.locale"
                      :placeholder="$t('promotionAirline.placeholder.flightStartTime')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="new Date()"
                      :max="promotionDataToEdit.flightEndTime ? new Date(promotionDataToEdit.flightEndTime) : null"
                      :disabled="readonly"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      flip
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Flight end date -->
                <b-col md="4">
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.flightEndTime') }}
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToEdit.flightEndTime"
                      type="date"
                      :locale="$i18n.locale"
                      :placeholder="$t('promotionAirline.placeholder.flightEndTime')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="promotionDataToEdit.flightStartTime ? new Date(promotionDataToEdit.flightStartTime) : new Date()"
                      :disabled="readonly"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      flip
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR minimum Passenger -->
                <b-col md="4">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('promotionAirline.fields.minimumPassenger')"
                    rules="max:5"
                  >
                    <b-form-group>
                      <template #label>
                        <div>
                          {{ $t('promotionAirline.fields.minimumPassenger') }}
                        </div>
                      </template>
                      <b-form-input
                        v-model="promotionDataToEdit.minimumPassenger"
                        v-remove-non-numeric-chars.allNumber
                        number
                        maxlength="5"
                        lazy-formatter
                        :disabled="readonly"
                        :formatter="trimInput"
                        :placeholder="$t('promotionAirline.placeholder.minimumPassenger')"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="mb-0">
                <!-- ANCHOR description -->
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAirline.fields.description') }}
                      </div>
                    </template>
                    <b-form-textarea
                      v-model="promotionDataToEdit.description"
                      rows="3"
                      :disabled="readonly"
                      :placeholder="$t('promotionAirline.placeholder.description')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        <!-- !SECTION -->

        </b-form>
        <!-- !SECTION -->

        <!--  ANCHOR Button Actions -->
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            :class="!readonly ? 'mr-50' : ''"
            @click="backHandle()"
          >
            <feather-icon
              icon="RotateCcwIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('customer.back') }}</span>
          </b-button>
          <b-button
            v-if="!readonly"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="ml-50"
            :disabled="readonly || invalid || !canAccess('customer.updateCustomer')"
            @click="nextHandle()"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />
            <span class="align-middle"> {{ $t('customer.save') }} </span>
          </b-button>
        </div>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  BButton,
  BOverlay,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import router from '@/router'
import {
  airlineFilterOptions,
  airlineOptions, statusAgencyOptions,
  typeOfDiscountOptions, typeOfDiscountPaidOptions,
} from '@/constants/selectOptions'
import store from '@/store'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import { avatarText } from '@core/utils/filter'

import usePromotionAirlineHandle from '@promotionAirline/usePromotionAirlineHandle'
import promotionAirlineStoreModule from '@promotionAirline/promotionAirlineStoreModule'
import {
  email, integer, isUnique,
  max,
  min,
  phoneNumber,
  required,
} from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const promotionData = ref(null)
    const loading = ref(true)
    const PROMOTION_APP_STORE_MODULE_NAME = 'app-promotionAirline'

    // Register module
    if (!store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_APP_STORE_MODULE_NAME, promotionAirlineStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_APP_STORE_MODULE_NAME)
      }
    })

    const promotionDataToEdit = ref({})
    const resetPromotionData = () => {
      promotionDataToEdit.value = JSON.parse(JSON.stringify(promotionData.value))
    }

    store.dispatch('app-promotionAirline/getPromotionAirlineById', { id: router.currentRoute.params.id })
      .then(({ data }) => {
        const normalizeData = {
          ...data,
        }
        promotionData.value = normalizeData
        resetPromotionData()
      })
      .catch(error => {
        if (error) {
          promotionData.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPromotionData)
    const {
      updatePromotionAirline,
    } = usePromotionAirlineHandle()

    const readonly = ref(true)
    const isReset = ref(false)

    function nextHandle() {
      const dataToUpdate = getDifference(
        promotionDataToEdit.value,
        promotionData,
      )
      this.$bvModal.show('modal-api-loading')
      updatePromotionAirline(promotionDataToEdit.value, dataToUpdate).then(() => {
        readonly.value = true
      }).finally(() => {
        this.$bvModal.hide('modal-api-loading')
      })
    }

    const onCancel = () => {
      resetForm()
      router.go(-1)
    }

    function handleEdit() {
      readonly.value = false
      isReset.value = false
    }

    function handleCancelEdit() {
      isReset.value = true
      readonly.value = true
    }

    function backHandle() {
      router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    return {
      promotionDataToEdit,
      refFormObserver,
      getValidationState,
      avatarText,

      // select options
      airlineOptions,
      statusAgencyOptions,
      airlineFilterOptions,
      typeOfDiscountOptions,
      typeOfDiscountPaidOptions,

      // Validations
      required,
      min,
      max,
      email,
      integer,
      isUnique,
      phoneNumber,

      readonly,
      isReset,
      loading,
      // Methods
      onCancel,
      handleEdit,
      handleCancelEdit,

      backHandle,
      nextHandle,
      clearHandle,
      trimInput,
      upperCaseFormatter,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
