var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid;
        return [_c('b-form', [_c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.edit.title')) + " ")]), _vm.readonly ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "py-75",
                attrs: {
                  "variant": "outline-primary",
                  "disabled": !_vm.canAccess('customer.updateCustomer')
                },
                on: {
                  "click": _vm.handleEdit
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "RefreshCwIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('customer.edit')))])], 1) : _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "py-75",
                attrs: {
                  "variant": "outline-danger",
                  "disabled": !_vm.canAccess('customer.updateCustomer')
                },
                on: {
                  "click": _vm.handleCancelEdit
                }
              }, [_c('feather-icon', {
                staticClass: "mr-50",
                attrs: {
                  "icon": "XIcon"
                }
              }), _c('span', {
                staticClass: "align-middle"
              }, [_vm._v(_vm._s(_vm.$t('customer.cancel')))])], 1)], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1 mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAirline.fields.code'),
            "rules": "required|code|max:100"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.code')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "maxlength": "100",
                  "minlength": "1",
                  "disabled": _vm.readonly,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('promotionAirline.placeholder.code'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.code,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "code", $$v);
                  },
                  expression: "promotionDataToEdit.code"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAirline.fields.airline'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "mb-0",
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.airline')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                staticStyle: {
                  "font-size": "1rem"
                },
                attrs: {
                  "options": _vm.airlineFilterOptions,
                  "clearable": false,
                  "disabled": _vm.readonly,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "placeholder": _vm.$t('promotionAirline.placeholder.airline')
                },
                on: {
                  "close": validationContext.validate
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(data) {
                    return [_c('div', {
                      staticClass: "d-flex-center justify-content-between"
                    }, [_c('span', {
                      staticClass: " d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref2) {
                    var loading = _ref2.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.promotionDataToEdit.airline,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "airline", $$v);
                  },
                  expression: "promotionDataToEdit.airline"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', {
          staticClass: "mb-2"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAirline.fields.startTime'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.startTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "type": "date",
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('promotionAirline.placeholder.startTime'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(),
                  "max": _vm.promotionDataToEdit.endTime ? new Date(_vm.promotionDataToEdit.endTime) : null,
                  "disabled": _vm.readonly,
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "flip": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.startTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "startTime", $$v);
                  },
                  expression: "promotionDataToEdit.startTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAirline.fields.endTime'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.endTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "type": "date",
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('promotionAirline.placeholder.endTime'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": _vm.promotionDataToEdit.startTime ? new Date(_vm.promotionDataToEdit.startTime) : new Date(),
                  "disabled": _vm.readonly,
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "flip": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.endTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "endTime", $$v);
                  },
                  expression: "promotionDataToEdit.endTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAirline.fields.priority'),
            "rules": "max:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.priority')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "number": "",
                  "maxlength": "2",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAirline.placeholder.priority'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.priority,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "priority", $$v);
                  },
                  expression: "promotionDataToEdit.priority"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.flightStartTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "type": "date",
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('promotionAirline.placeholder.flightStartTime'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": new Date(),
            "max": _vm.promotionDataToEdit.flightEndTime ? new Date(_vm.promotionDataToEdit.flightEndTime) : null,
            "disabled": _vm.readonly,
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "flip": ""
          },
          model: {
            value: _vm.promotionDataToEdit.flightStartTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "flightStartTime", $$v);
            },
            expression: "promotionDataToEdit.flightStartTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.flightEndTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "type": "date",
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('promotionAirline.placeholder.flightEndTime'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": _vm.promotionDataToEdit.flightStartTime ? new Date(_vm.promotionDataToEdit.flightStartTime) : new Date(),
            "disabled": _vm.readonly,
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "flip": ""
          },
          model: {
            value: _vm.promotionDataToEdit.flightEndTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "flightEndTime", $$v);
            },
            expression: "promotionDataToEdit.flightEndTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAirline.fields.minimumPassenger'),
            "rules": "max:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.minimumPassenger')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "number": "",
                  "maxlength": "5",
                  "lazy-formatter": "",
                  "disabled": _vm.readonly,
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAirline.placeholder.minimumPassenger'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToEdit.minimumPassenger,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToEdit, "minimumPassenger", $$v);
                  },
                  expression: "promotionDataToEdit.minimumPassenger"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', {
          staticClass: "mb-0"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAirline.fields.description')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "3",
            "disabled": _vm.readonly,
            "placeholder": _vm.$t('promotionAirline.placeholder.description')
          },
          model: {
            value: _vm.promotionDataToEdit.description,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToEdit, "description", $$v);
            },
            expression: "promotionDataToEdit.description"
          }
        })], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          class: !_vm.readonly ? 'mr-50' : '',
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.backHandle();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RotateCcwIcon"
          }
        }), _c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('customer.back')))])], 1), !_vm.readonly ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "ml-50",
          attrs: {
            "variant": "outline-primary",
            "disabled": _vm.readonly || invalid || !_vm.canAccess('customer.updateCustomer')
          },
          on: {
            "click": function click($event) {
              return _vm.nextHandle();
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "RefreshCwIcon"
          }
        }), _c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.save')) + " ")])], 1) : _vm._e()], 1)];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }